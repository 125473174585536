import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.clientSettings.authority,
  requireHttps: environment.production,

  // URL of the SPA to redirect the user to after login
  // redirectUri: window.location.origin
  //   + ((localStorage.getItem('useHashLocationStrategy') === 'true')
  //     ? '/#/index.html'
  //     : '/index.html'),

  redirectUri: environment.publicUrl + '/auth/callback',
  postLogoutRedirectUri: environment.publicUrl,

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: environment.publicUrl + '/silent-refresh.html',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.clientSettings.client_id,

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: environment.clientSettings.scope,

  useSilentRefresh: true,
  useIdTokenHintForSilentRefresh: true,
  // silentRefreshShowIFrame: true,

  showDebugInformation: true,

  sessionChecksEnabled: true,

  // timeoutFactor: 0.01,
};

import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/auth/user.service';
import { environment } from 'src/environments/environment';

import { authConfig } from './auth-config';
import { LoginClickDirective } from './login-click.directive';
import { LogoutClickDirective } from './logout-click.directive';
import { StorageService } from './storage-service';

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [LoginClickDirective, LogoutClickDirective],
  imports: [
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.api_url],
        sendAccessToken: true,
      },
    }),
  ],
  exports: [LoginClickDirective, LogoutClickDirective],
  // This is initialised here so that it's guaranteed to happen on app init
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeOAuthService,
      multi: true,
      deps: [OAuthService, UserService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUser,
      multi: true,
      deps: [UserService],
    },
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
})
export class AuthModule {}

function initializeOAuthService(
  oauthService: OAuthService,
  userService: UserService
) {
  return async () => {
    oauthService.configure(authConfig);
    // const result = await oauthService.loadDiscoveryDocumentAndTryLogin();
    // if (!(result && oauthService.hasValidAccessToken())) {
    //   return;
    // }
    if (await userService.reloadUserProfile()) {
      await userService.reloadUserData();
    }
  };
}
function initializeUser(userService: UserService) {
  return async () => {
    if (!StorageService.isSSR) {
      await userService.currentUser$.pipe(take(1)).toPromise();
    }
  };
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MaintenanceComponent } from './shared/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((x) => x.AccountModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth-ui/auth-ui.module').then((x) => x.AuthUiModule),
  },
  {
    path: 'fourteenfish',
    loadChildren: () =>
      import('./fourteen-fish/fourteen-fish.module').then(
        (x) => x.FourteenFishModule
      ),
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./unsubscribe/unsubscribe.module').then(
        (x) => x.UnsubscribeModule
      ),
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  { path: '', redirectTo: '/account/overview', pathMatch: 'full' },
  {
    path: 'webinar-launcher',
    loadChildren: () =>
      import('./webinar-launcher/webinar-launcher.module').then(
        (m) => m.WebinarLauncherModule
      ),
  },
  { path: '**', redirectTo: '/account/overview' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

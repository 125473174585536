import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-redirect-handler',
  templateUrl: './redirect-handler.component.html',
  styleUrls: ['./redirect-handler.component.scss'],
})
export class RedirectHandlerComponent implements OnInit {
  static redirectTo = new EventEmitter<string>();
  constructor(
    private currentUserService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    // this.currentUserService.loggedOutRedirect$.subscribe((extras) => {
    //   this.router.navigate(['/login'], extras);
    // });
    RedirectHandlerComponent.redirectTo.subscribe((url) => {
      this.router.navigateByUrl(url);
    });
  }
}

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DashboardTitleService {
  private baseName = 'NB Medical Dashboard';
  setTitle(...title: string[]): void {
    if (!title || title.length === 0) {
      this.title.setTitle(this.baseName);
    } else {
      this.title.setTitle(`${title.join(' - ')} - ${this.baseName}`);
    }
  }

  constructor(private title: Title) {}
}

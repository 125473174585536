import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { AccountFormComponent } from './account-form/account-form.component';
import { AddToHomeScreenComponent } from './add-to-home-screen/add-to-home-screen.component';
import { CardContentComponent } from './card-content/card-content.component';
import { CheckPwaUpdatesComponent } from './check-pwa-updates/check-pwa-updates.component';
import { ClarityLinkDirective } from './clarity-link.directive';
import { ClarityLinkComponent } from './clarity-link/clarity-link.component';
import { ContentTypeDirective } from './content-type.directive';
import { DashboardTitleService } from './dashboard-title.service';
import { DayjsDatePipe } from './dayjs-date.pipe';
import { FileDownloaderDirective } from './file-downloader.directive';
import { GpHorizonBannerComponent } from './gp-horizon-banner/gp-horizon-banner.component';
import { AngularDateHttpInterceptor } from './interceptors/date.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { KeyboardShortcuts } from './keyboard-shortcuts.service';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MinutesHoursPipe } from './minutes-hours.pipe';
import { MinutesSecondsPipe } from './minutes-seconds.pipe';
import { ModalButtonGroupComponent } from './modal-button-group/modal-button-group.component';
import { NewlineToBreaksPipe } from './newline-to-breaks.pipe';
import { PageViewTrackerComponent } from './page-view-tracker/page-view-tracker.component';
import { PwaTargetDirective } from './pwa-target.directive';
import { RedirectHandlerComponent } from './redirect-handler/redirect-handler.component';
import { SafePipe } from './safe.pipe';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { SlugifyPipe } from './slugify.pipe';
import { SocialLinksComponent } from './social-links/social-links.component';
import { TitleUpdaterComponent } from './title-updater/title-updater.component';
import { ToastsContainer } from './toasts/toasts-container.component';
import { UkDateParserFormatter } from './uk-date-parser-formatter';

@NgModule({
  declarations: [
    ContentTypeDirective,
    SafePipe,
    MinutesSecondsPipe,
    ClarityLinkComponent,
    CheckPwaUpdatesComponent,
    PageViewTrackerComponent,
    AddToHomeScreenComponent,
    FileDownloaderDirective,
    TitleUpdaterComponent,
    ClarityLinkDirective,
    PwaTargetDirective,
    MinutesHoursPipe,
    RedirectHandlerComponent,
    MaintenanceComponent,
    ModalButtonGroupComponent,
    NewlineToBreaksPipe,
    AccountFormComponent,
    SearchFilterComponent,
    ToastsContainer,
    SocialLinksComponent,
    GpHorizonBannerComponent,
    DayjsDatePipe,
    SlugifyPipe,
    CardContentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDatepickerModule,
    NgbToastModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    KeyboardShortcuts,
    DashboardTitleService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AngularDateHttpInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: UkDateParserFormatter },
  ],
  exports: [
    ContentTypeDirective,
    SafePipe,
    MinutesSecondsPipe,
    MinutesHoursPipe,
    NewlineToBreaksPipe,
    ClarityLinkComponent,
    PageViewTrackerComponent,
    CheckPwaUpdatesComponent,
    RedirectHandlerComponent,
    AddToHomeScreenComponent,
    NgbModule,
    ToastsContainer,
    FileDownloaderDirective,
    TitleUpdaterComponent,
    ClarityLinkDirective,
    PwaTargetDirective,
    ModalButtonGroupComponent,
    NgbDatepickerModule,
    AccountFormComponent,
    SearchFilterComponent,
    SocialLinksComponent,
    GpHorizonBannerComponent,
    DayjsDatePipe,
    SlugifyPipe,
    CardContentComponent,
  ],
})
export class SharedModule {}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserInfo } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { UserService } from 'src/app/auth/user.service';
import { ImpersonationDataService } from 'src/app/impersonation/impersonation-data.service';
import { environment } from 'src/environments/environment';

const IMPERSONATION_HEADER = 'x-impersonation-id';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private impersonationDataService: ImpersonationDataService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(environment.api_url)) {
      return next.handle(request);
    }

    // Check the current user token and append it to the Authorization header of the request
    return this.userService.currentUser$.pipe(
      first(),
      mergeMap((user) => {
        const headers = this.getUserHeaders(user);
        request = request.clone({
          setHeaders: {
            'Cache-Control':
              'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0',
            ...headers,
          },
        });
        // if (headers) {
        //   request = request.clone({
        //     setHeaders: headers,
        //   });
        // }

        // Pass off the modified request to the next http handler in the chain
        return next.handle(request);
      })
    );
  }

  private getUserHeaders(user: UserInfo) {
    if (!UserService.isAuthenticated(user)) {
      return {};
    }
    const accessToken = this.userService.getAccessToken();
    const data = {
      Authorization: `Bearer ${accessToken}`,
    };
    const impersonationData = this.getImpersonatingData(user);
    if (impersonationData) {
      data[IMPERSONATION_HEADER] = impersonationData.id;
    }
    return data;
  }

  private getImpersonatingData(user: UserInfo) {
    if (!UserService.canImpersonate(user)) {
      return undefined;
    }
    return this.impersonationDataService.getData();
  }
}

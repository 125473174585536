import { UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject, Subject } from 'rxjs';

import { UserData } from './shared/user-data';

class AppStateData {
  constructor() {
    this.initialize();
  }
  get isUserInitialized() {
    return this.user$.value !== this.initialUserInfo;
  }
  get isUserDataInitialized() {
    return this.userData$.value !== this.initialUserDataInfo;
  }
  private initialUserInfo = {} as UserInfo;
  private initialUserDataInfo = {} as UserData;
  public user$: BehaviorSubject<UserInfo>;
  public userData$: BehaviorSubject<UserData>;
  public loggedIn$: BehaviorSubject<boolean>;
  public impersonationStateChanged = new Subject<any>();

  private initialize() {
    this.user$ = new BehaviorSubject<UserInfo>(this.initialUserInfo);
    this.userData$ = new BehaviorSubject<UserData>(this.initialUserDataInfo);
    this.loggedIn$ = new BehaviorSubject<boolean>(false);
    this.impersonationStateChanged.subscribe(() => {
      this.userData$.next(this.initialUserDataInfo);
    });
  }
}

export const AppState = new AppStateData();

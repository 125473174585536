<section>
  <div class="container">
    <img src="../../../assets/imgs/logo.svg" alt="logo" />
    <h2>Under maintenance</h2>
    <div class="maintenance">
      {{ message }}
    </div>
  </div>
  <article>
    <a href="https://gphorizon.com/" target="_blank">
      <img src="../../../assets/imgs/gp_banner.svg" alt="banner" />
    </a>
    <a href="https://gphorizon.com/" target="_blank">Visit the Website</a>
  </article>
</section>

export class UserData {
  constructor(
    public id: string,
    public email: string,
    public title: string,
    public firstName: string,
    public lastName: string,
    public contactSalesforceId: string,
    public hasValidClarityToken: boolean,
    public hasValidFourteenFishInfo: boolean,
    public hasFourteenFishAutoSync: boolean,
    public sendInfoByEmail: boolean,
    public sendInfoByPost: boolean,
    public canImpersonate: boolean,
    public trackTime: boolean // public isNBPlusSubscriber: boolean, // public nbPlusAddOnCategorisation: NBPlusAddonCategorisation | null, // public info: { type: string; data: any }[]
  ) {}

  public get displayName(): string {
    return this.displayParts.join(' ');
  }

  private get displayParts(): string[] {
    const parts = [];
    if (this.title) {
      parts.push(this.title);
    }
    if (this.firstName) {
      parts.push(this.firstName);
    }
    if (this.lastName) {
      parts.push(this.lastName);
    }
    return parts;
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var gtag;

@Component({
  selector: 'app-page-view-tracker',
  templateUrl: './page-view-tracker.component.html',
  styleUrls: ['./page-view-tracker.component.scss']
})
export class PageViewTrackerComponent implements OnInit {
  constructor(
    private router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document
  ) {}

  ngOnInit() {
    this.addScripts();
    this.sendNavigationEvents();
  }

  private addScripts() {
    const mainTag = this._renderer2.createElement('script');
    mainTag.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsCode}`;
    mainTag.async = 'async';

    this._renderer2.appendChild(this._document.body, mainTag);

    const gtagTag = this._renderer2.createElement('script');
    gtagTag.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
    `;
    this._renderer2.appendChild(this._document.body, gtagTag);
  }
  private sendNavigationEvents() {
    const navEndEvent$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', environment.googleAnalyticsCode, {
        page_path: e.urlAfterRedirects
      });
    });
  }
}

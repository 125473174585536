import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { StorageService } from '../auth/storage-service';
import { UserData } from '../shared/user-data';

const key = 'impersonated-user-data';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationDataService {
  constructor() {}
  private data = new BehaviorSubject<UserData>(this.loadFromLocalStorage());
  setData(userData: UserData) {
    this.data.next(userData);
    StorageService.setItem(key, JSON.stringify(userData));
    // AppState.impersonationStateChanged.next({});
    window.location.reload();
  }

  getData() {
    return this.data.value;
  }
  private loadFromLocalStorage(): UserData {
    if (StorageService.isSSR) {
      return null;
    }
    const data = StorageService.getItem(key);
    if (!data) {
      return null;
    }
    const dataObject = JSON.parse(data);
    if (dataObject === null || dataObject === undefined) {
      return null;
    }
    return new UserData(
      dataObject.id,
      dataObject.email,
      dataObject.title,
      dataObject.firstName,
      dataObject.lastName,
      dataObject.contactSalesforceId,
      dataObject.hasValidClarityToken,
      dataObject.hasValidFourteenFishInfo,
      dataObject.hasFourteenFishAutoSync,
      dataObject.sendInfoByEmail,
      dataObject.sendInfoByPost,
      dataObject.canImpersonate,
      dataObject.trackTime
      // dataObject.isNBPlusSubscriber,
      // dataObject.nbPlusAddOnCategorisation,
      // dataObject.info
    );
  }
}

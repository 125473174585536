import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ApiResponse } from '../api-response';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Inspect the response to see its success status and unpack the relevant part to pass back up the chain
    return next.handle(request).pipe(
      map((ev: HttpEvent<ApiResponse>) => {
        if (request.url.startsWith(environment.api_url)) {
          if (ev.type === HttpEventType.Response) {
            if (!ev.body.success) {
              const newResponse: HttpEvent<any> = ev.clone<any>({
                body: ev.body.error,
              });
              return newResponse;
            }

            const newRespone: HttpEvent<any> = ev.clone<any>({
              body: ev.body.response,
            });
            return newRespone;
          }
        }

        return ev;
      })
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { UserNBPlusInfo } from '../widgets/nb-plus-upsell/user-nb-plus-info';
import { errorParser } from './error-parser';
import { UserData } from './user-data';

const key = 'currentUserToken';
const infoKey = 'currentUserInfo';
const redirectKey = 'redirectUrl';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  //   get userFromStorage(): UserAuth {
  //     const token = localStorage.getItem(key);
  //     return this.deserialiseUser(token);
  //   }
  //   get userInfoFromStorage(): UserInfo {
  //     const token = localStorage.getItem(infoKey);
  //     return this.deserialiseUserInfo(token);
  //   }

  //   public get currentUser(): UserAuth {
  //     return this.$currentUser.value;
  //   }

  //   public get currentUserInfo(): UserInfo {
  //     return this.$currentUserInfo.value;
  //   }

  constructor(private httpClient: HttpClient) {}
  //   public static userTokenUpdated = new EventEmitter<any>();
  //   checkInterval: any;
  //   public $currentUser: BehaviorSubject<UserAuth> = new BehaviorSubject<
  //     UserAuth
  //   >(this.userFromStorage);
  //   public $currentUserInfo: BehaviorSubject<UserInfo> = new BehaviorSubject<
  //     UserInfo
  //   >(this.userInfoFromStorage);
  //   public loggedOutRedirect$ = new EventEmitter<NavigationExtras>();

  //   startPoll() {
  //     if (this.currentUser) {
  //       this.refreshUserInfo();
  //     }
  //     this.checkInterval = setInterval(() => {
  //       if (this.currentUser) {
  //         this.refreshUserInfo();
  //       }
  //     }, 1000 * 60 * 5);
  //   }

  //   setCurrentUserToken(token: string) {
  //     if (token) {
  //       localStorage.setItem(key, token);
  //     } else {
  //       localStorage.removeItem(key);
  //     }
  //     this.updateUser();
  //     if (token) {
  //       this.refreshUserInfo();
  //     }
  //   }

  //   refreshUserInfo() {
  //     this.getUserInfo().subscribe((info) => {
  //       this.setCurrentUserInfo(info);
  //     });
  //   }

  //   setCurrentUserInfo(info: any) {
  //     let changed = false;
  //     if (info) {
  //       const existing = localStorage.getItem(infoKey);
  //       const newData = JSON.stringify(info);
  //       changed = existing !== newData;
  //       if (changed) {
  //         localStorage.setItem(infoKey, newData);
  //       }
  //     } else {
  //       changed = localStorage.getItem(infoKey) !== null;
  //       if (changed) {
  //         localStorage.removeItem(infoKey);
  //       }
  //     }
  //     if (changed) {
  //       this.updateUserInfo();
  //     }
  //   }

  getUserInfo() {
    return this.httpClient.get<UserData>(`${environment.api_url}/me`);
  }
  getNBPlusInfo() {
    return this.httpClient.get<UserNBPlusInfo>(
      `${environment.api_url}/me/nb-plus`
    );
  }

  //   private updateUser() {
  //     this.$currentUser.next(this.userFromStorage);
  //   }

  //   private updateUserInfo() {
  //     this.$currentUserInfo.next(this.userInfoFromStorage);
  //   }

  updateDbUser(formData: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${environment.api_url}/me`, formData)
      .pipe(catchError(errorParser));
  }

  //   deserialiseUser(token: string): UserAuth {
  //     if (!token) {
  //       return null;
  //     }
  //     try {
  //       const json = jwt_decode(token) as any;
  //       return new UserAuth(
  //         json.id,
  //         json.impersonateId,
  //         this.getDateFromTicks(json.iat),
  //         this.getDateFromTicks(json.exp),
  //         token
  //       );
  //     } catch (err) {
  //       console.error('could not parse token', token, err, err.stack);
  //       return null;
  //     }
  //   }
  //   deserialiseUserInfo(token: string): UserInfo {
  //     if (!token) {
  //       return null;
  //     }
  //     try {
  //       const json = JSON.parse(token) as any;
  //       return new UserInfo(
  //         json.id,
  //         json.email,
  //         json.title || '',
  //         json.firstName || '',
  //         json.lastName || '',
  //         json.hasValidClarityToken,
  //         json.sendInfoByEmail,
  //         json.sendInfoByPost,
  //         json.canImpersonate,
  //         json.trackTime,
  //         json.loyaltyDiscountExpiry,
  //         json.info || []
  //       );
  //     } catch (err) {
  //       console.error('could not parse token', token, err, err.stack);
  //       return null;
  //     }
  //   }

  //   private getDateFromTicks(ticks: number) {
  //     const date = new Date(0);
  //     date.setUTCSeconds(ticks);
  //     return date;
  //   }

  //   public logout(extras?: NavigationExtras) {
  //     localStorage.removeItem(key);
  //     localStorage.removeItem(infoKey);
  //     // localStorage.removeItem(redirectKey);
  //     if (this.checkInterval) {
  //       clearInterval(this.checkInterval);
  //     }
  //     this.updateUser();
  //     this.updateUserInfo();
  //     this.loggedOutRedirect$.emit(extras);
  //   }
}

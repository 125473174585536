import { throwError } from 'rxjs';

import { ApiResponseError } from './api-response';

export function errorParser(error: ApiResponseError) {
  if (error.details && error.details.length) {
    return throwError(`${error.message}: ${error.details.join(', ')}`);
  }
  return throwError(error.message);
}

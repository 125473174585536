import { Component, OnInit } from '@angular/core';

import { MaintenanceService } from '../maintenance.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  constructor(private maintenenceService: MaintenanceService) {}
  private static defaultMessage =
    'Sorry, the NB Medical Dashboard is currently down for maintenence. We will be back up and running shortly.';
  message = MaintenanceComponent.defaultMessage;

  ngOnInit() {
    this.maintenenceService.startPollForMaintenenceCompletion();

    this.maintenenceService.message$.subscribe((m) => {
      this.message = m || MaintenanceComponent.defaultMessage;
    });
  }
}

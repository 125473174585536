import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/auth/login.service';

import { MaintenenceStatus } from '../maintenance-status';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private loginService: LoginService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          // this.loginService.logout();
          return of({} as any);
        }
        if (err.status === 503) {
          MaintenenceStatus.isUnderMaintenance();
          return of({} as any);
        }

        if (err.error && err.error.error) {
          // If the error has been returned by the API it will be an object with an "error" property
          return throwError(err.error.error);
        }

        // Other, unexpected errors, such as being unable to connect to the API will not be of the same
        // type, so just throw the raw error
        return throwError(err);
      })
    );
  }
}

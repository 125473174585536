import { BehaviorSubject } from 'rxjs';

import { RedirectHandlerComponent } from './redirect-handler/redirect-handler.component';

const key = 'under-maintenance';
export class MaintenenceStatus {
  static get storedValue(): boolean {
    const value = localStorage.getItem(key);
    if (!value) {
      return false;
    }
    try {
      return JSON.parse(key) as boolean;
    } catch {
      return false;
    }
  }
  public static underMaintenance$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(MaintenenceStatus.storedValue);
  static finished() {
    localStorage.removeItem(key);
    MaintenenceStatus.underMaintenance$.next(false);
    RedirectHandlerComponent.redirectTo.emit('/account/overview');
  }
  static isUnderMaintenance() {
    localStorage.setItem(key, JSON.stringify(true));
    MaintenenceStatus.underMaintenance$.next(true);
    RedirectHandlerComponent.redirectTo.emit('/maintenance');
  }
}

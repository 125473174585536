import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { DashboardTitleService } from '../dashboard-title.service';

@Component({
  selector: 'app-title-updater',
  templateUrl: './title-updater.component.html',
  styleUrls: ['./title-updater.component.scss']
})
export class TitleUpdaterComponent implements OnInit {
  constructor(
    private router: Router,
    private dashboardTitle: DashboardTitleService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.routerState),
        map((state) => {
          let route = state.snapshot.root;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => {
          return route.outlet === 'primary';
        })
      )
      .subscribe((route) => {
        if (route.data.title) {
          this.dashboardTitle.setTitle(route.data.title);
        } else {
          this.dashboardTitle.setTitle();
        }
      });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import * as dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AppState } from '../app-state';
import { ImpersonationDataService } from '../impersonation/impersonation-data.service';
import { UserData } from '../shared/user-data';
import { UserManagementService } from '../shared/user-management.service';
import { StorageService } from './storage-service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private oauthService: OAuthService,
    private userManagementService: UserManagementService,
    private impersonationDataService: ImpersonationDataService,
    private http: HttpClient
  ) {}
  get currentUser$(): Observable<UserInfo> {
    return AppState.user$;
  }

  get currentUserData$(): Observable<UserData> {
    if (this.impersonationDataService.getData()) {
      return of(this.impersonationDataService.getData());
    }
    if (AppState.isUserDataInitialized) {
      return AppState.userData$;
    }
    AppState.userData$.next(null);
    return this.loadDataFromServer();
  }

  static isAuthenticated(user: UserInfo): boolean {
    if (!user) {
      return false;
    }
    const expiresAt = StorageService.getItem('expires_at');
    if (!expiresAt) {
      return false;
    }
    const expiresAtValue = parseInt(expiresAt, 10);
    if (isNaN(expiresAtValue) || expiresAtValue <= 0) {
      return false;
    }
    return dayjs().isBefore(dayjs(expiresAtValue));
  }

  static getUserRoles(user: UserInfo): string[] {
    return user != null && this.isAuthenticated && user.role
      ? Array.isArray(user.role)
        ? user.role
        : [user.role]
      : [];
  }

  static userIsInRole(user: UserInfo, role: string) {
    const roles = this.getUserRoles(user);
    return roles.some((x) => x === role);
  }
  static canImpersonate(user: UserInfo) {
    return UserService.userIsInRole(user, 'Impersonator');
  }
  static canTrackTime(user: UserInfo) {
    return UserService.userIsInRole(user, 'TimeTracking');
  }
  static hasValidClarityToken(user: UserData) {
    return user.hasValidClarityToken;
  }
  // static getAuthorizationHeaderValue(user: UserInfo): string {
  //   const isAuthenticated = this.isAuthenticated(user);
  //   return !isAuthenticated ? null : `${user.token_type} ${user.access_token}`;
  // }
  // static getAccessToken(user: UserInfo) {
  //   const isAuthenticated = this.isAuthenticated(user);
  //   return !isAuthenticated ? null : user.access_token;
  // }
  static getUserName(user: UserInfo): string {
    return user?.name || '';
  }

  private loadDataFromServer() {
    return this.userManagementService.getUserInfo().pipe(
      tap((userData) => {
        const data = this.getUserDataClass(userData);
        AppState.userData$.next(data);
      }),
      mergeMap((_) => AppState.userData$)
    );
  }
  private getUserDataClass(userData: UserData) {
    return new UserData(
      userData.id,
      userData.email,
      userData.title || '',
      userData.firstName || '',
      userData.lastName || '',
      userData.contactSalesforceId,
      userData.hasValidClarityToken,
      userData.hasValidFourteenFishInfo,
      userData.hasFourteenFishAutoSync,
      userData.sendInfoByEmail,
      userData.sendInfoByPost,
      userData.canImpersonate,
      userData.trackTime
    );
  }

  async reloadUserProfile() {
    let hasValidToken = false;
    try {
      await this.oauthService.loadDiscoveryDocument();
      hasValidToken = this.oauthService.hasValidAccessToken();
    } catch {}

    if (!hasValidToken) {
      return false;
    }

    // we're doing this manually ourselves because the loadUserProfile() version below fails silently when it fails and breaks
    const infoResult = await this.getUserInfo();
    if (!infoResult) {
      this.oauthService.logOut();
      return false;
    }
    try {
      const user: any = await this.oauthService.loadUserProfile();
      const info = user?.info ?? null;
      AppState.user$.next(info);
      return info !== null;
    } catch {
      return false;
    }
  }
  getUserInfo() {
    const headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.getAccessToken()
    );
    return this.http
      .get(this.oauthService.userinfoEndpoint, {
        headers,
        observe: 'response',
        responseType: 'text',
      })
      .toPromise();
  }
  async reloadUserData() {
    try {
      const user = await this.userManagementService.getUserInfo().toPromise();
      if (user) {
        const data = this.getUserDataClass(user);
        // if (this.impersonationDataService.getData()) {
        //   this.impersonationDataService.setData(data);
        // } else {
        AppState.userData$.next(data);
        // }
      }
    } catch {}
  }

  getAccessToken() {
    return this.oauthService.getAccessToken();
  }
  // private static getUserFromStorage(): UserInfo {
  //   const userData = UserService.storage.getItem(userKey);
  //   if (userData) {
  //     return JSON.parse(userData);
  //   }
  //   return null;
  // }
  setUser(user: UserInfo) {
    AppState.user$.next(user);
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-check-pwa-updates',
  templateUrl: './check-pwa-updates.component.html',
  styleUrls: ['./check-pwa-updates.component.scss'],
})
export class CheckPwaUpdatesComponent implements OnInit {
  constructor(private swUpdate: SwUpdate, private router: Router) {}

  fullRefresh = false;

  ngOnInit() {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    // check every 5 minutes
    setInterval(() => this.swUpdate.checkForUpdate(), 1000 * 60 * 5);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd)
      )
      .subscribe((start) => {
        if (this.fullRefresh) {
          location.reload();
        }
      });
    this.swUpdate.available.subscribe((event) => {
      this.fullRefresh = true;
      // if not reloaded within 2 minutes from update, do the reload
      setInterval(() => location.reload(), 1000 * 60 * 2);
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ApiResponse } from './api-response';
import { MaintenenceStatus } from './maintenance-status';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  interval: any;
  message$ = new Subject<string>();
  constructor(private httpClient: HttpClient) {}
  startPollForMaintenenceCompletion() {
    this.checkMaintenance();
    this.interval = setInterval(() => this.checkMaintenance(), 5000);
  }
  private checkMaintenance() {
    this.httpClient
      .get<ApiResponse>(`${environment.api_url}/maintenance`)
      .subscribe((value) => {
        MaintenenceStatus.underMaintenance$.next(value.maintenance);
        if (!value.maintenance) {
          clearInterval(this.interval);
          MaintenenceStatus.finished();
        }
        if (value.maintenanceMessage) {
          this.message$.next(value.maintenanceMessage);
        }
      });
  }
}

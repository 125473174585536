export const environment = {
  production: true,
  api_url: 'https://licence-dashboardapi-nb.preview-site.website',
  website_url: 'https://licence-website-nb.preview-site.website',
  website_api_url: 'https://licence-api-nb.preview-site.website',
  clarityEndpoint: 'https://id.clarity-connect.co.uk',
  clarityRedirectUri:
    'https://licence-dashboard-nb.preview-site.website/account/overview',
  fileTypeWhitelist:
    '.gif,.jpeg,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv',
  googleAnalyticsCode: 'UA-65393069-2',
  isMobile: false,
  publicUrl: 'https://licence-dashboard-nb.preview-site.website',
  clientSettings: {
    authority: 'https://licence-auth-nb.preview-site.website',
    client_id: 'dashboard',
    scope: 'openid profile email dashboard file-download',
  },
  fourteenFishAppIntegrationId: 'nbmedicaldev',
};
